<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCol sm="12" class="mt-2">
                <CRow>                    
                    <CCol sm="6">
                        <CSelect
                            :label="$t('label.country')"
                            :options="optionsListPaises"
                            :horizontal="{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-7'}"
                            addLabelClasses="text-right"
                            class="pl-0"
                            :disabled="apiStateLoading"
                            :value.sync="$store.state.ubicacion.filtroPais"
                            @change="$store.commit('ubicacion/mutationfiltroPais', $event.target.value)"
                        />                        
                    </CCol>
                    <CCol sm="6" class="d-flex justify-content-end">
                        <div>            
                            <CButton
                                color="add"
                                @click="activarModal()"
                                v-c-tooltip="{placement:'top-end',content:$t('label.state')}"
                            >
                                <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                            </CButton>
                        </div>
                    </CCol>                    
                </CRow>
                <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedItems"
                    :fields="fields"
                    :loading="loading"
                    :noItemsView="tableText.noItemsViewText"                
                    :items-per-page="5"
                    pagination
                >           
                    <template #loading>
                        <loading/>
                    </template>      
                    <template #StateId="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="text-center">
                            <CBadge v-if="item.status" color="success">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                            <CBadge v-if="!item.status" color="danger">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #acciones="{item, index}">
                        <td class="text-center">
                            <CButton
                                color="edit"
                                square
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{
                                    content: $t('label.edit')+$t('label.state'),
                                    placement: 'top-end'
                                }"
                                @click="updateModal(item, index)"
                            >
                                <CIcon name="pencil"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
                

            </CCol>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import General from "@/_mixins/general";
    import { mapState,mapMutations } from 'vuex';
    

    const dataEstado = [];
    
    function fields(){

    return [
        { key: 'StateId',label: '#', filter :false,_classes:'center-cell text-center', _style: 'width:5%;', },
        /*{ key: 'NbPais',label: 'NOMBRE DEL PAÍS', _style:'min-width:100px;' },*/
        { key: 'StateName',label: this.$t('label.state'),_classes:'center-cell text-center',_style:'width:50%;' },
        { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:'center-cell text-center', _style: 'width: 14%;' },
        { key: 'FormatedDate', label:this.$t('label.date'),_classes:'center-cell text-center', _style: 'width: 14%;'},
        { key: 'Status',label: this.$t('label.status'),_classes:'center-cell text-center', _style:'width:14%; text-align:center;' },
        { 
            key: 'acciones', 
            label: '', 
            _style: 'width:45px', 
            sorter: false, 
            filter: false
        }
    ]
    }
    //data
    function data() {
        return {                       
            optionsPais:[],
            activePage: 1,
            warningModal:false,
            company:[],
        }
    }

    //computed
    function computedItems () {
        return this.$store.getters["ubicacion/myDataEstadotable"];
    }
    function formatedItems() {
        if(this.computedItems.length === 0)
            return []
    
        return this.computedItems.filter(item => {
            return item
        })
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }    
    function optionsListPaises(){
        if(this.myDataPais.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select')
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.select')
            }];
            this.myDataPais.map(function(e){
                if(e.FgActCountry){
                    chart.push({
                        value: e.CountryId, 
                        label: e.CountryName,
                    })
                }
            })
            return chart;
        }
    }

    //methods
    function pageChange(val) {
        this.$router.push({ query: { page: val }});
    }
    function activarModal(){
        this.$store.commit('ubicacion/mutationModalEstado', true);
    }
    function updateModal(item,index){
        this.$store.commit('ubicacion/mutationModalEstado', true);
        this.$store.commit('ubicacion/asignarid', item.StateId);
    }
    //watch
    function busqueda(newQuestion,Oldquestion){
        if(newQuestion !== ""){
            let idPais = newQuestion;
            this.$store.dispatch('ubicacion/getEstadolist', {idPais});
        }else{
            this.$store.state.ubicacion.myDataEstado = [];
        }
    }
    
    function handlerRoute() {
        return {
            immediate: true,
            handler (route) {
                if (route.query && route.query.page) {
                this.activePage = Number(route.query.page)
                }
            }
        };
    }
    

    export default {
        name: 'app-estado',
        data,
        props: ['loading','Tab','Mounted'],
        mixins: [General],       
        watch: {
            filtroPais: {
                deep:true,
                handler:busqueda
            },
            $route: handlerRoute,
        
            Tab:function (val){
                if(val == 1){
                    let idPais = this.$store.state.ubicacion.filtroPais;
                    if (this.$store.state.ubicacion.filtroPais && this.Mounted==false) {
                        this.$store.dispatch('ubicacion/getEstadolist', {idPais});
                    }else{
                        this.$store.state.ubicacion.myDataEstado = [];
                    }
                }
            },
        
        },
        methods: {
            pageChange,
            activarModal,
            updateModal,
        },
        computed: {
             fields,
            computedItems,
            formatedItems,
            apiStateLoading,
            optionsListPaises,
            ...mapState({
                apiState: state => state.ubicacion.apiState,
                myDataPais: state => state.ubicacion.myDataPais,
                filtroPais: state => state.ubicacion.filtroPais,
            })
        }
    }
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
 
</style>